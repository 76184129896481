.button {
  text-align: right;
}

.button button {
  width: 200px; /* Change to your desired width */
  height: 40px; /* Change to your desired height */
  border-radius: 15px; /* Change to your desired border radius */
  background-color: rgb(31, 119, 160); /* Change to your desired color */
  color: #fff;
  border: none;
}
