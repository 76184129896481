@import '../../style/custonVariable';
//crm tile

.addLeadContainer {
    padding: 15px;
}

.card_1 {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    height: 100%;

    @media (max-width: 768px) {
        margin-bottom: 15px;
        height: 90%;
        ;
    }
}


.ModalContent{
    padding: 1em;
}
.AddressField {
    width: fit-content;
    margin-top: 9px;
    padding: 10px;
    border: dotted;
    border-color: #80808047 !important;
}

.Walletheading{
    text-transform: lowercase;
    text-transform: uppercase;
    font-weight: bolder;
    color: #3a8775;
    font-family: 'poppinsmedium';
}
.updateBTn{
    padding: 13px;
    border-radius: 10px;
    background: #3396FF !important;
    border: none;
}
.updateBTn:hover{
    background: #2876c9 !important;
}
.wallectConnect {
    margin-top: 3px;
    margin-right: 3px;
    margin-bottom: 21px;
    iframe {
        display: none;
    }

    // button{
    //     width:120px !important;
    // }

}

.card_body {
    flex: 1 1 auto;
    padding: 1.25rem;

    @media (max-width: 768px) {
        padding: 0 !important;
        padding-top: 10px !important;
    }

    .stat_widget_seven {
        min-height: 106px;
        max-height: 91px;
    }

    h3 {
        font-size: 24px !important;
        text-align: left;

        @media (max-width: 768px) {
            font-size: 20px !important;
        }
    }

    h5 {
        font-size: 14px;
        text-align: left;
    }

}

.media {
    font-size: .9em;
    display: flex;
    overflow: hidden;
    align-items: flex-start;

    :first-child {
        margin-top: 0;
        cursor: pointer;
    }

    .media_body {
        display: table-cell;
        vertical-align: top;
        width: 10000px;

        @media (max-width: 768px) {
            width: 98%;
        }
    }
}

.bg_primary {
    color: #f4f3f9;
    background-color: $dashboard-color-blue;
}

.bg-info {
    color: #f4f3f9;
    background-color: $color__primary;
}

.border_in {
    border-bottom: 1px solid #fff;

}

.panel {
    border-radius: 2px;
    // box-shadow: 0 0 0.875rem 0 rgb(53 64 82 6%);
    margin-bottom: 20px;
    background-color: #fff;
    // border: 1px solid transparent;
    padding: 10px;
}

.panel_default {
    border-color: #dee5e7;
}

.panel_body {
    padding: 15px;
    // margin-top: 41px;
    // :after
    // {
    //     display: table;
    //     content: " ";
    //     clear: both;
    // }

}

.table_responsive {
    min-height: .01% !important;
    overflow-x: auto !important;

    thead {
        background-color: #f6f8f8;
        color: #131e25;
        background: #f6f8f8;
    }

    td {
        i {
            cursor: pointer;
        }
    }
}

.table {
    width: 100%;
    max-width: 100%;
}

.progress {
    height: 20px !important;
}

.bgSuccess {
    background-color: green;
}

.text_info {
    color: #23b7e5;
}

.text_primary {
    color: #7266ba;
}

.timeLineContent {
    padding: 15px;
}

.leadDetailsContent,
.leadHistoryContent {
    padding: 15px;
    background-color: $color-white;

    .addSpan {
        color: $color__primary;
        cursor: pointer;
    }
}

.modal {
    .modal_header {
        background-color: #7266ba;
        text-align: center;
        color: #fff;
    }

    .modal_content {
        border: 1px solid rgba(0, 0, 0, .2);
    }

    .modal_body {
        background-color: #fff;
    }
}

h4 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.add_followup_btn {
    background: #ffd7009e;
    padding: 5px;
    color: #000;
    text-decoration: none !important;
    cursor: pointer;
}

.timeline {
    background: #fff;
    width: 100%;
    height: 100%;
    padding: 0;
    font-family: 'Roboto Condensed', sans-serif;
    -webkit-font-smoothing: antialiased;
    padding: 0px 10% !important;

    .demo_card_wrapper {
        flex-flow: column wrap;
        width: 717px;
        margin: 0 auto;
        position: relative;

        &::after {
            border-left: 1px solid #bdbdbd;
            z-index: 1;
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 54%;
        }
    }

    .demo_card {
        max-width: 330px;
        margin: 0;
        margin-top: 45px;
        margin-bottom: 45px;
        // box-shadow: 0 1px 22px 4px rgb(0 0 0);
        position: relative;
        display: block;
        margin: 10px auto 80px;
        max-width: 94%;
        z-index: 2;

        &:nth-child(even) {
            margin-left: 58%;
            margin-top: 0;

            .head::after {
                position: absolute;
                content: "";
                width: 0;
                height: 0;
                border-top: 15px solid transparent;
                border-bottom: 15px solid transparent;
                border-right-width: 15px;
                border-right-style: solid;
                right: 100%;
            }

            .head::before {
                right: 327px;
                position: absolute;
                content: "";
                width: 9px;
                height: 9px;
                background-color: #bdbdbd;
                border-radius: 9px;
                box-shadow: 0 0 2px 8px #f7f7f7;
            }
        }

        &:nth-child(odd) {
            margin-right: 50%;

            .head::before {
                left: 383px;
                position: absolute;
                content: "";
                width: 9px;
                height: 9px;
                background-color: #bdbdbd;
                border-radius: 9px;
                box-shadow: 0 0 2px 8px #f7f7f7;
            }

            .head::after {
                border-left-width: 15px;
                border-left-style: solid;
                left: 100%;
                position: absolute;
                content: "";
                width: 0;
                height: 0;
                border-top: 15px solid transparent;
                border-bottom: 15px solid transparent;
            }
        }

        &:nth-child(1) {
            order: 1;
        }

        .head {
            position: relative;
            display: flex;
            align-items: center;
            color: #fff;
            font-weight: 400;

            .number_box {
                display: inline;
                float: left;
                margin: 15px;
                padding: 10px;
                font-size: 35px;
                line-height: 35px;
                font-weight: 600;
                background: rgba(0, 0, 0, 0.17);
            }
        }

        .body {
            background: #fff;
            border: 1px solid rgba(191, 191, 191, 0.4);
            border-top: 0;
            padding: 15px;

            p {
                font-size: 14px;
                line-height: 18px;
                margin-bottom: 15px;
                overflow-wrap: break-word;
            }
        }
    }

    .demo_card_step2 {
        background-color: #7166ba;

        .head::after {
            border-color: #7166ba;
        }
    }

    .demo_card_step1 {
        background-color: #46b8e9;

        .head::after {
            border-color: #46b8e9;
        }
    }
}

.ModalHeader {
    // background-image: linear-gradient(to right, #7266ba, #4A3388);
    background-image: linear-gradient(to right, #66ba6f, #222026FC);
    text-align: center;
    display: block;
    padding-right: 2rem;
    padding-left: 2rem;
    color: $color-white;
    border: none;

    h3 {
        margin-top: 20px;
        margin-bottom: 10px;

        i {
            -webkit-font-smoothing: antialiased;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            line-height: 1;
        }
    }

    .ModalTitle {
        font-size: 30px;
    }

    p {
        color: $color-white;
    }

    button {
        color: white !important;
    }
}

.attachment {
    background: #46b8e9;
    padding: 5px;
    color: #fff;
    text-decoration: none;
}

.btn {
    font-weight: 500;
    border-radius: 2px;
    outline: 0 !important;
}

.datePicker {
    width: 100%;
    z-index: 1000 !important;
    height: 34px;
    font-size: 0.75rem;
    color: black !important;
    padding: 5px 12px !important;
    border-radius: 3px !important;
    border: 1px solid #cfdadd !important;
    height: 34px;

    &:disabled {
        cursor: not-allowed;
    }
}

.datePicker:focus {
    border: none;
}

.otp_pop_sc input {
    height: 50px;
}

.selectfile {
    position: absolute;
    top: 74%;
    font-size: 12px;
    padding: 4px;
    color: red;

}



.CalendarContainer {
    width: 100%;
    z-index: 10 !important;
    height: 34px;
    font-size: 0.75rem;
    color: black !important;
    padding: 5px 12px !important;
    border-radius: 3px !important;
    border: 1px solid #cfdadd !important;
    height: 34px;

    &:disabled {
        cursor: not-allowed;
    }
}

@media (max-width: 768px) {
    .demo_card_wrapper {
        width: 100% !important;
    }

    .timeline {
        padding: 0px 0 !important;
    }

    .demo_card:nth-child(odd) {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .demo_card:nth-child(even) {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .demo_card .head::after {
        display: none !important;
    }

    .demo_card .head::before {
        display: none !important;
    }

    .demo_card .head::before {
        display: none !important;
    }

    .demo_card .head::after {
        display: none !important;
    }

    .demo_card_wrapper::after {
        display: none;
    }

    .demo_card {
        margin-bottom: 20px !important;
    }
}


/****************************************** dark mode *******************************/


.addLeadContainer {
    padding: 15px;
}

.card_1 {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    height: 100%;

    @media (max-width: 768px) {
        margin-bottom: 15px;
        height: 90%;
        ;
    }
}

.card_body {
    flex: 1 1 auto;
    padding: 1.25rem;

    @media (max-width: 768px) {
        padding: 0 !important;
        padding-top: 10px !important;
    }

    .stat_widget_seven {
        min-height: 106px;
        max-height: 91px;
    }

    h3 {
        font-size: 24px !important;
        text-align: left;

        @media (max-width: 768px) {
            font-size: 20px !important;
        }
    }

    h5 {
        font-size: 14px;
        text-align: left;
    }

}

.media {
    font-size: .9em;
    display: flex;
    overflow: hidden;
    align-items: flex-start;

    :first-child {
        margin-top: 0;
        cursor: pointer;
    }

    .media_body {
        display: table-cell;
        vertical-align: top;
        width: 10000px;

        @media (max-width: 768px) {
            width: 98%;
        }
    }
}

.bg_primary {
    color: #f4f3f9;
    background-color: $dashboard-color-blue;
}

.bg-info {
    color: #f4f3f9;
    background-color: $color__primary;
}

.border_in {
    border-bottom: 1px solid #fff;

}

.dark_mode_panel {
    border-radius: 2px;
    // box-shadow: 0 0 0.875rem 0 rgb(53 64 82 6%);
    margin-bottom: 20px;
    //background-color: #fff;
    // border: 1px solid transparent;
    padding: 10px;
}

.panel_default {
    border-color: #dee5e7;
}

.panel_body {
    padding: 15px;
    // margin-top: 41px;
    // :after
    // {
    //     display: table;
    //     content: " ";
    //     clear: both;
    // }
}

.dark_mode_table_responsive {
    min-height: .01% !important;
    overflow-x: auto !important;

    thead {
        //background-color: #f6f8f8;
        color: #ffffff;
        //background: #f6f8f8;
    }

    td {
        i {
            cursor: pointer;
        }
    }
}

.table {
    width: 100%;
    max-width: 100%;
}

.progress {
    height: 20px !important;
}

.bgSuccess {
    background-color: green;
}

.text_info {
    color: #23b7e5;
}

.text_primary {
    color: #7266ba;
}

.timeLineContent {
    padding: 15px;
}

.dark_mode_leadDetailsContent,
.dark_mode_leadHistoryContent {
    padding: 15px;

    //background-color: $color-white;
    .addSpan {
        color: white !important;
        cursor: pointer;
    }
}

.modal {
    .modal_header {
        background-color: #7266ba;
        text-align: center;
        color: #fff;
    }

    .modal_content {
        border: 1px solid rgba(0, 0, 0, .2);
    }

    .modal_body {
        background-color: #fff;
    }
}

h4 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.add_followup_btn {
    background: #ffd7009e;
    padding: 5px;
    color: #000;
    text-decoration: none !important;
    cursor: pointer;
}

.dark_mode_timeline {
    background: #03142B;
    width: 100%;
    height: 100%;
    padding: 0;
    font-family: 'Roboto Condensed', sans-serif;
    -webkit-font-smoothing: antialiased;
    padding: 0px 10% !important;

    .demo_card_wrapper {
        flex-flow: column wrap;
        width: 717px;
        margin: 0 auto;
        position: relative;

        &::after {
            border-left: 1px solid #bdbdbd;
            z-index: 1;
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 54%;
        }
    }

    .demo_card {
        max-width: 330px;
        margin: 0;
        margin-top: 45px;
        margin-bottom: 45px;
        // box-shadow: 0 1px 22px 4px rgb(0 0 0);
        position: relative;
        display: block;
        margin: 10px auto 80px;
        max-width: 94%;
        z-index: 2;

        &:nth-child(even) {
            margin-left: 58%;
            margin-top: 0;

            .head::after {
                position: absolute;
                content: "";
                width: 0;
                height: 0;
                border-top: 15px solid transparent;
                border-bottom: 15px solid transparent;
                border-right-width: 15px;
                border-right-style: solid;
                right: 100%;
            }

            .head::before {
                right: 327px;
                position: absolute;
                content: "";
                width: 9px;
                height: 9px;
                background-color: #bdbdbd;
                border-radius: 9px;
                box-shadow: 0 0 2px 8px #f7f7f7;
            }
        }

        &:nth-child(odd) {
            margin-right: 50%;

            .head::before {
                left: 383px;
                position: absolute;
                content: "";
                width: 9px;
                height: 9px;
                background-color: #bdbdbd;
                border-radius: 9px;
                box-shadow: 0 0 2px 8px #f7f7f7;
            }

            .head::after {
                border-left-width: 15px;
                border-left-style: solid;
                left: 100%;
                position: absolute;
                content: "";
                width: 0;
                height: 0;
                border-top: 15px solid transparent;
                border-bottom: 15px solid transparent;
            }
        }

        &:nth-child(1) {
            order: 1;
        }

        .head {
            position: relative;
            display: flex;
            align-items: center;
            color: #fff;
            font-weight: 400;

            .number_box {
                display: inline;
                float: left;
                margin: 15px;
                padding: 10px;
                font-size: 35px;
                line-height: 35px;
                font-weight: 600;
                background: rgba(0, 0, 0, 0.17);
            }
        }

        .body {
            background: #fff;
            border: 1px solid rgba(191, 191, 191, 0.4);
            border-top: 0;
            padding: 15px;

            p {
                font-size: 14px;
                line-height: 18px;
                margin-bottom: 15px;
                overflow-wrap: break-word;
            }
        }
    }

    .demo_card_step2 {
        background-color: #7166ba;

        .head::after {
            border-color: #7166ba;
        }
    }

    .demo_card_step1 {
        background-color: #46b8e9;

        .head::after {
            border-color: #46b8e9;
        }
    }
}

.ModalHeader {
    // background-image: linear-gradient(to right, #7266ba, #4A3388);
    background-image: linear-gradient(to right, #66ba6f, #222026FC);
    text-align: center;
    display: block;
    padding-right: 2rem;
    padding-left: 2rem;
    color: $color-white;
    border: none;

    h3 {
        margin-top: 20px;
        margin-bottom: 10px;

        i {
            -webkit-font-smoothing: antialiased;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            line-height: 1;
        }
    }

    .ModalTitle {
        font-size: 30px;
    }

    p {
        color: $color-white;
    }
}

.attachment {
    background: #46b8e9;
    padding: 5px;
    color: #fff;
    text-decoration: none;
}

.btn {
    font-weight: 500;
    border-radius: 2px;
    outline: 0 !important;
}

.datePicker {
    width: 100%;
    z-index: 1000 !important;
    height: 34px;
    font-size: 0.75rem;
    color: black !important;
    padding: 5px 12px !important;
    border-radius: 3px !important;
    border: 1px solid #cfdadd !important;
    height: 34px;

    &:disabled {
        cursor: not-allowed;
    }
}

.datePicker:focus {
    border: none;
}

.otp_pop_sc input {
    height: 50px;
}




.CalendarContainer {
    width: 100%;
    z-index: 10 !important;
    height: 34px;
    font-size: 0.75rem;
    color: black !important;
    padding: 5px 12px !important;
    border-radius: 3px !important;
    border: 1px solid #cfdadd !important;
    height: 34px;

    &:disabled {
        cursor: not-allowed;
    }
}

@media (max-width: 768px) {
    .demo_card_wrapper {
        width: 100% !important;
    }

    .timeline {
        padding: 0px 0 !important;
    }

    .demo_card:nth-child(odd) {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .demo_card:nth-child(even) {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .demo_card .head::after {
        display: none !important;
    }

    .demo_card .head::before {
        display: none !important;
    }

    .demo_card .head::before {
        display: none !important;
    }

    .demo_card .head::after {
        display: none !important;
    }

    .demo_card_wrapper::after {
        display: none;
    }

    .demo_card {
        margin-bottom: 20px !important;
    }
}

.wallectConnect{
    position: fixed;
    top: 10px;
    z-index: 999;
    // right: 247px;
    right: 300px;
    font-size: 14px;
    text-align: right;
    h4{text-align: center;}
    form{text-align: center;}
    .walletconnected_bx{
        background-color: #fff;
        padding: 10px;
        box-shadow: 0px 6px 30px #ccc;
        margin-top: 10px;
        display: none;
    }
  
}
.wallectConnect:hover .walletconnected_bx{display: block;}
@media (max-width:800px) {
    .wallectConnect{position: relative;float: left;width: 100%;background-color: #fff;
        padding: 10px;box-shadow: 0px 6px 30px #ccc;text-align: center;top: 0;right: 0;}
    .walletconnected_bx{display: inline-block !important;box-shadow: none !important;margin-left: 10px;width: 100% !important;}
        .AddressField{margin: auto;word-break: break-all;}
}


.dark_mode_graph_box_crm {
    width: 100%;
    height: auto;
    padding: 15px;
    /* box-shadow: 0px 0px 20px #e5e5e5; */
    /* background-color: #fff; */
    border-radius: 10px;
    margin-bottom: 15px;
}

.dark_mode_textArea {
    font-size: 15px;
    height: 70px !important;
    background-color: #253548 !important;
    color: white !important;
    border: none;
}

.dark_mode_left_right_color {
    background-color: #1a2649;
}

.dark_mode_center_color {
    background-color: #23b7e545;
}

/**************************************** end of dark mode *************************/